<template>
  <svg :width="width" :height="width" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.7666" cy="11.7666" r="8.98856" stroke="#a5a5a5" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.0183 18.4851L21.5423 22" stroke="#a5a5a5" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "SearchSvg",
  props: {
    width: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped>

</style>

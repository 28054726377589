<template>
  <div>
    <AddToCompare :compares="compares" />
    <main class="main product-details-main">
      <PagesPath path-title1="لیست محصولات" path-title2="مقایسه" pathTo1="/products"/>
      <div class="container text-right mt-md-5 mt-3">
        <template v-if="products">
          <div class="row">
            <a :class="'col-' + col"
               @click="(e) => goTo(e,{name:'product.detail' , params:{id: product.id , slug: product.slug}})"
               :href="$router.resolve({name:'product.detail' , params:{id: product.id , slug: product.slug}}).href"
               v-for="product in products" :key="product.id" class="compare-item">
              <img @click="remove(product)" class="close" :src="require('@/assets/images/close.png')" alt="Remove">
              <img class="main-image" :src="getImage(product.major_image, 'lg')" :alt="product.title">
              <h6 class="text-black mt-2">
                {{ product.title }}
              </h6>
              <button class="fontsize-small text-white-hover mx-auto mb-3
               bg-color-theme px-1 py-1 px-sm-3 py-sm-2 text-white mt-sm-3 mx-auto d-inline-block d-none d-sm-block">
                مشاهده <template v-if="!mediaQueries.mobileSize">و خرید </template>
              </button>
            </a>
            <div @click="addCompare" class="add-compare" :class="'col-' + col"
                 v-if="products && products.length < (col == 4 ? 3 : 4)">
              <img :width="col == 4 ? 100 : 200"
                   class="d-block mx-auto"
                   :src="require('@/assets/images/add.png')" alt="افزودن کالای جدید">
              <div>برای افزودن کالا کلیک کنید</div>
            </div>
          </div>
          <div v-if="specifications && specifications !== ''" class="compare-table">
            <div class="attribute" v-for="item in specifications" :key="item.id">
              <div class="attribute-title">
                <span>{{ item.label }}</span>
              </div>
              <div class="row">
                <div v-for="(product,index) in products" :key="index" class="value" :class="'col-' + col">
                  <span>{{ getSpecificationValue(product, item) }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="d-flex justify-content-center" v-else>
          <Loading/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import Loading from '@/parts/Front/components/shared/Loading'
import {getImage} from '@/libraries/functions'
import AddToCompare from "@/parts/Front/components/shared/AddToCompare";

export default {
  name: "Compare",
  components: {AddToCompare, PagesPath, Loading},
  inject: ['mediaQueries'],
  data() {
    return {
      compares: [],
      products: null,
      specifications: [],
    }
  },
  created() {
    if (!this.$route.query.compares) {
      this.$router.replace('/products')
    }
    this.loadProducts()
  },
  watch: {
    '$route.query.compares': function (newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        this.loadProducts()
      } else {
        this.compares = newValue.split(',')
        this.filterProducts();
      }
    }
  },
  methods: {
    getImage,
    addCompare() {
      this.$root.$emit('openCompare', {
        categories: this.products[0].categories
      })
    },
    async loadProducts() {
      this.compares = this.$route.query.compares.split(',')

      try {
        const response = await this.$axios.get('front/products/compare', {
          params: {
            compares: this.compares
          }
        })
        this.products = response.data.data.products
        this.products.forEach(p => {
          p.specifications.forEach(sp => {
            const alreadyExist = this.specifications.find(_sp => {

              return _sp.id == sp.id
            });
            if (!alreadyExist) {
              this.specifications.push(sp)
            }
          })
        })
      } catch (e) {
        this.$root.notify(e)
      }
    },
    getSpecificationValue(product, specification) {
      let foundSpecification = product.specifications.find(sp => sp.id == specification.id);
      if (!foundSpecification) {
        return '';
      }
      if (foundSpecification.pivot && foundSpecification.pivot.specification_values != '') {
        let s = ''
        foundSpecification.pivot.specification_values.forEach((fSp, index) => {
          s += fSp.value + (index != foundSpecification.pivot.specification_values.length - 1 ? ' - ' : '')
        })

        return s
      } else if (foundSpecification.pivot.specification_value) {
        return foundSpecification.pivot.specification_value.value
      } else {
        return foundSpecification.pivot.value
      }
    },
    goTo(e, url) {
      e.preventDefault()
      if (e.target.classList.contains('close')) {
        return
      }
      this.$router.push(url)
    },
    remove(product) {
      let id = product.id;
      let path = this.$route.fullPath
      let final = ''
      if (path.includes(',' + id)) {
        final = path.replace(',' + id, '')
      } else {
        final = path.replace(id + ',', '')
      }
      this.$router.push(final)
    },
    filterProducts() {
      this.products = this.products.filter(p => this.compares.find(c => c == p.id));
    }
  },
  computed: {
    col() {
      return this.mediaQueries.mobileSize ? '4' : '3'
    },
  }
}
</script>

<style scoped>
.main-image {
  max-width: 100%;
}

.close {
  position: absolute;
  top: -31px;
  right: 0;
}

.attribute-title {
  display: block;
  text-align: right;
  background: #eaeaea;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: bold;
}

.value {
  padding: 9px 14px;
  text-align: center;
}

.btn {
  border-radius: 8px;
  padding: 6px 31px;
  color: #fff;
  transition: 200ms all;
  margin: auto;
}
.btn:hover {
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.05);
}


.add-compare {
  border-radius: 19px;
  border: 5px dashed #d2d2d2;
  background: none;
  max-width: 270px;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
  padding: 35px;
  font-size: 15px;
  font-size: 1.071rem;
  line-height: 1.467;
  color: #b3b3b3;
  cursor: pointer;
}

@media screen and (max-width: 768px){
  .compare-item {
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
  }

  .add-compare {
    padding: unset;
    padding-right: 0px;
    padding-left: 0px;
    margin-top: unset;
    font-size: 0.9rem;
    text-align: center;
    border: 0;
  }

  .close {
    width: 40px;
    top: -10px;
  }
}

</style>
